import dynamicImportPolyfill from 'dynamic-import-polyfill';

// Dev only styles. Will not generate anything for production builds
import './style/base/reset.scss';

// Main Stylesheet
import './style/styles.scss';

import init from './index';

// This needs to be done before any dynamic imports are used.
// If your modules are hosted in a sub-directory, it must be specified here.
dynamicImportPolyfill.initialize({ modulePath: './', });

// Start the app.
init();
