import initGallery from '../scripts/initGallery';

export default function init() {
  function activateInteractiveElement() {
    window.activateInteractiveElement
  = window.activateInteractiveElement || initGallery;

    initGallery();

    if (window.webkit) {
      const styles = window.getComputedStyle(document.body);
      const margin = parseFloat(styles.marginTop)
                 + parseFloat(styles.marginBottom);
      window.addEventListener('load', event => {
        const { height, } = document.body.getBoundingClientRect();
        window.webkit.messageHandlers.sizeChanged.postMessage(height + margin + 42);
      });
    }
  }
  window.activateInteractiveElement
= window.activateInteractiveElement || activateInteractiveElement;

  activateInteractiveElement();
}
