/* eslint-disable no-param-reassign */
import { unEscape, } from './utils';

const container = document.querySelector('[data-warel-images]');
const imageContainers = Array.from(document.querySelectorAll('[data-warel-image-num]'));
const nameContainers = Array.from(document.querySelectorAll('[data-warel-image-name]'));
const galleryImages = container.querySelectorAll('img');

const images = JSON.parse(unEscape(container.dataset.warelImages));
const names = JSON.parse(unEscape(container.dataset.warelNames));
const details = JSON.parse(unEscape(container.dataset.warelDetails));

const data = names.map((name, idx) => ({
  name,
  url: images[idx],
  detail: details[idx],
}));

const shuffledData = data.sort((a, b) => 0.5 - Math.random());
shuffledData.length = 10;

let isActive = false;
let imageNum = 0;
let galleryTimeout = null;

export default function initGallery() {
  // Observer
  const galleryObserver = new IntersectionObserver(toggleGallery);

  galleryObserver.observe(container);

  function toggleGallery(entries) {
    entries.forEach(entry => {
      isActive = entry.isIntersecting;
      if (isActive) galleryTimeout = setTimeout(setImageData, 3600);
      else clearTimeout(galleryTimeout);
    });
  }

  galleryImages.forEach(galleryImage => {
    galleryImage.onload = () => {
      clearTimeout(galleryTimeout);
      const imageContainer = galleryImage.parentElement;
      imageContainer.classList.add('active');
      imageContainers.forEach(el => {
        if (el.dataset.warelImageNum !== imageContainer.dataset.warelImageNum) el.classList.remove('active');
      });
      if (isActive) galleryTimeout = setTimeout(setImageData, 3600);
    };
  });
  setImageData();
}

function setImageData() {
  imageNum = imageNum === shuffledData.length - 1 ? 0 : imageNum += 1;
  const nextContainer = imageContainers.find(imgContainer => !imgContainer.classList.contains('active')) || imageContainers[0];

  const nextImage = nextContainer.querySelector('img');

  const nextName = nameContainers.find(cont => cont.dataset.warelImageName === nextContainer.dataset.warelImageNum);
  const url = shuffledData[imageNum].url;
  nextImage.srcset = `${url}width=110&height=120 120w, ${url}width=140&height=140 140w, ${url}width=160&height=160 160w, ${url}width=180&height=180 180w, ${url}width=260&height=260 260w`;

  const name = shuffledData[imageNum].name;
  const detail = shuffledData[imageNum].detail && shuffledData[imageNum].detail !== '-'
    ? `<br /><span>${shuffledData[imageNum].detail}</span> `
    : '';

  nextName.innerHTML = `<em>${name}</em>${detail}`;
  nameContainers.forEach(nameCont => {
    if (nameCont.dataset.warelImageName === nextContainer.dataset.warelImageNum) nameCont.classList.add('active');
    else nameCont.classList.remove('active');
  });
}
