/* eslint-disable no-param-reassign */

const tooltip = document.querySelector('[class*="map__tooltip"]');

export function removeTooltip() {
  tooltip.classList.remove('active');
}

export function unEscape(htmlStr) {
  htmlStr = htmlStr.replace(/&lt;/g, '<');
  htmlStr = htmlStr.replace(/&gt;/g, '>');
  htmlStr = htmlStr.replace(/&quot;/g, '"');
  htmlStr = htmlStr.replace(/&#39;/g, '\'');
  htmlStr = htmlStr.replace(/&amp;/g, '&');
  return htmlStr;
}
